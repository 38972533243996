import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const headers = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) {
  }

  GetSystemData(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_system_data', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewUser(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_user', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }


  GetBonus(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_bonus', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log("post response");
        console.log(JSON.stringify(data));
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewCar(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_car', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewCoupon(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_coupon', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewParking(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_parking', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }


  RequestPhoneVerifyCode(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('request_phone_verify_code', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AdminLogin(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('admin_login', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AdminReset(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('admin_reset', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AdminForget(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('admin_forget', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UserLogin(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('user_login', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CheckPhoneRegistered(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('check_phone_registered', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllCarDataExcludingDisabledByEngineGroupByModel(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_car_data_excluding_disabled_by_engine_group_by_model', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllCarDataExcludingDisabledByModelGroupByColorCode(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_car_data_exluding_disabled_by_model_group_by_color_code', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetNewCarOrderDataTemplate(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_new_order_data_template', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllRentableParkingDataExcludingDisabled(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_rentable_parking_data_excluding_disabled', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UploadBase64FileToServer(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_base64_file_to_server', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetUserDataById(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_data_by_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateUser(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_user', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateCampaign(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_campaign', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewCampaign(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_campaign', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateCoupon(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_coupon', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateDepositHandler(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_deposit_handler', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateParking(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_parking', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateCar(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_car', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateSystemData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_system_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GeneratePaymentData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_payment_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  ApplyCouponToPaymentByCouponCode(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('apply_coupon_to_payment_by_coupon_code', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CalculateCarRentalAmountByBookingDaysAndCarIdAndUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('calculate_car_rental_amount_by_booking_days_and_car_id_and_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AddCarModelToFavorite(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('add_car_model_to_favorite', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  RemoveCarModelToFavorite(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_car_model_to_favorite', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllViolationDataExcludingDisabledByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_violation_data_excluding_disabled_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UserSubmitViolationPaymentImg(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('user_submit_violation_payment_img', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewEmergency(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_emergency', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateEmergency(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_emergency', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateBlocking(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_blocking', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllUserBookingDateListByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_user_booking_date_list_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAvailableCarByBookingDateList(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_available_car_by_booking_date_list', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAvailableCarByBookingDateListAndModelAndColorCode(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_available_car_by_booking_date_list_and_model_and_color_code', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllCarDataExcludingDisabledByEngineAndBookingDateListGroupByModelAndColorCode(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_car_data_excluding_disabled_by_engine_and_booking_date_list_group_by_model_and_color_code', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAvailableEquipmentByBookingDateListAndCarIdGroupByCatergory(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_available_equipment_by_booking_date_list_and_car_id_group_by_category', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllBuyableProductDataExcludingDisabledByCarId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_buyable_product_data_excluding_disabled_by_car_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllUsableCouponDataByUserIdAndCarIdAndPaymentId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_usable_coupon_data_by_user_id_and_car_id_and_payment_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  MakeOrder(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('make_order', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  Pay(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('pay', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CalculatePaymentDataAmount(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('calculate_payment_data_amount', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAvailableCampaignByUserIdAndCarId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_available_campaign_by_user_id_and_car_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllOrderDataExcludingDisabledByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_order_data_excluding_disabled_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllOrderDataWithCarDataExcludingDisabledByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_order_data_with_car_data_excluding_disabled_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllAppointmentDataWithMaintenanceDataExcludingDisabledByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_appointment_data_with_maintenance_data_excluding_disabled_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllOrderDataWithCarDataWithPaymentDataListByOrderId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_order_data_with_car_data_with_payment_data_list_by_order_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetCurrentOrderDataWithCarDataWithPaymentDataListByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_current_order_data_with_car_data_with_payment_data_list_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAwaitingApplicationDepositAmountByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_awaiting_application_deposit_amount_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  ApplyReturnDepositByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('apply_return_deposit_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetDepositDataByUserId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_deposit_data_by_user_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  CMSExtendOrder(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_extend_order', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetMaintenanceDataExcludingDisabledAndNoAppliableFactory(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_maintenance_data_excluding_disabled_and_no_appliable_factory', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetMaintenanceDataWithFactoryDataListByMaintenanceId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_maintenance_data_with_factory_data_list_by_maintenance_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetReturnDataAndPickUpDataLimitByCarIdAndStartDateAndEndDate(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_return_data_and_pick_up_data_limit_by_car_id_and_start_date_and_end_date', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAppointmentDataBeforeNDaysByOrderIdAndMaintenanceId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_appointment_data_before_n_days_by_order_id_and_maintenance_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  MakeAppointment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('make_appointment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetUserDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetCouponDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_coupon_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetEmergencyDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_emergency_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetDepositDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_deposit_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetCampaignDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_campaign_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetEquipmentDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_equipment_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetParkingDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_parking_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetSingleDataByDataTypeAndId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetUserIdListByType(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_id_list_by_type', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataByDataType(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_by_data_type', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllUserData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_user_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataByDataTypeExcludingDisabled(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_by_data_type_excluding_disabled', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataExcludingDisabledByDataType(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_excluding_disabled_by_data_type', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataExcludingDisabledByDataTypeAndIdList(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_excluding_disabled_by_data_type_and_id_list', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataOptionalFieldByDataType(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_optional_field_by_data_type', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllUserDataOptionalField(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_user_data_optional_field', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetCarDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_car_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateEquipment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_equipment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewEquipment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_equipment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateProduct(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_product', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewProduct(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_product', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetProductDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_product_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetOrderDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetOrderDataWithAllOtherDataByRbbId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_with_all_other_data_by_rbb_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetRbbDataWithAllOtherDataByRbbId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_rbb_data_with_all_other_data_by_rbb_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateOrderHandler(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_order_handler', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        console.log(data);
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateRbbHandler(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_rbb_handler', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateOrder(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_order', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewOrder(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_order', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePayment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_payment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePaymentHandler(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_payment_handler', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateRbbPayment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_rbb_payment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  DeleteDataByDataTypeAndId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('delete_data_by_data_type_and_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllBlockingDataByCarId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_blocking_data_by_car_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewBlocking(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_blocking', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetEmergencyDataWithAllOtherDataByOrderId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_emergency_data_with_all_other_data_by_order_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetViolationDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_violation_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateViolation(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_violation', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewViolation(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_violation', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetFactoryDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_factory_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateFactory(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_factory', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewFactory(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_factory', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetMaintenanceDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_maintenance_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  UpdateMaintenance(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_maintenance', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewMaintenance(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_maintenance', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  GetAppointmentDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_appointment_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewAppointment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_appointment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAppointment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_appointment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAppointmentHandler(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_appointment_handler', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  GetGiftDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_gift_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  UpdateGift(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_gift', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewGift(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_gift', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewMultiGift(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_multi_gift', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePromotion(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_promotion', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewPromotion(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_promotion', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateSevenCoupon(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_seven_coupon', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewSevenCoupon(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_seven_coupon', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetPlateDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_plate_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  UpdatePlate(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_plate', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewPlate(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_plate', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewRbbEquipment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_rbb_equipment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateRbbEquipment(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_rbb_equipment', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetRbbEquipmentDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_rbb_equipment_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetRbbDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_rbb_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllRenderOrderDataExcludingDisabled(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_rendering_order_data_excluding_disabled', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetOrderDataWithAllOtherDataByOrderId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_with_all_other_data_by_order_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetDepositDataWithAllOtherDataByDepositId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_deposit_data_with_all_other_data_by_deposit_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewRbb(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_rbb', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  GetPromotionDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_promotion_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  GetSevenCouponDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_seven_coupon_filter', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllCarBrandList(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_car_brand_list', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetNotificationDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_notification_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }



  UpdateNotification(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_notification', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewOnesignalNotification(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_onesignal_notification', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  SendCustomSms(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('send_custom_sms', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateInvoicePdfByPaymentId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_invoice_pdf_by_payment_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateConfirmationPdfByOrderId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_confirmation_pdf_by_order_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateRBBConfirmation(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_rbb_confirmation', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateRBBAgreement(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_rbb_agreement', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAdminDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_admin_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewAdmin(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_admin', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAdmin(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_admin', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetFaqDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_faq_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  NewFaq(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_faq', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  UpdateFaq(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_faq', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetCarDataAndOrderDataForCmsHomeCalendar(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_car_data_and_order_data_for_cms_home_calendar', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetTotalAmountOfPaymentByPaymentCompletedDatetime(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_total_amount_of_payment_by_payment_completed_datetime', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllTotalAmountForCmsHomePage(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_total_amount_for_cms_home_page', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetLineChartTotalAmountList(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_line_chart_total_amount_list', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAdminNotificationDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_admin_notification_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }


  UpdateAdminNotification(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_admin_notification', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }
  GenerateChangeVehicleAgreementPdfByVrbData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_change_vehicle_agreement_pdf_by_vrb_data', JSON.stringify(send_date));
    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateVehicleRentalAgreementPdfByVrbData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_vehicle_rental_agreement_pdf_by_vrb_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateReturnVehicleReportPdfByRvrData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_return_vehicle_report_pdf_by_rvr_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateVehicleLicenseSignForConfirmationPdfByVlsData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_vehicle_sign_for_confirmation_pdf_by_vls_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateVehicleRegisterFormPdfPdfByVrfData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_vehicle_register_form_pdf_by_vrf_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateDepositAuthorizationPdfPdfByDafData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_deposit_authorization_form_pdf_by_daf_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GenerateReturnDepositReportPdfByDafData(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('generate_return_deposit_report_pdf_by_rdr_data', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetLogDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_log_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllFavoritedUserByCarModel(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_favorited_user_by_car_model', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllUnavailableDateByCarId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_unavailable_date_by_car_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  CheckCarAvailableByBookingDateListAndCarId(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('check_car_available_by_booking_date_list_and_car_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  SendFavoriteReminderByCarIdAndDate(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('send_favorite_reminder_by_car_id_and_date', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllEquipmentCompanyList(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_equipment_company_list', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetAllProductCompanyList(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_product_company_list', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetEquipmentSummaryReport(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_equipment_summary_report', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetProductSummaryReport(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_product_summary_report', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  GetPaymentDataAndTotalNumberBySortingAndLimitOrSearch(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_payment_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  ParkingDevToProd(): Promise<any> {
    let body = new URLSearchParams();
    body.set('parking_dev_to_prod', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  MaintenanceDevToProd(): Promise<any> {
    let body = new URLSearchParams();
    body.set('maintenance_dev_to_prod', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }
  

  CarDevToProd(): Promise<any> {
    let body = new URLSearchParams();
    body.set('car_dev_to_prod', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  
  GetAllForm(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_form', "");

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }

  
  GetForm(send_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_form_by_id', JSON.stringify(send_date));

    return new Promise((resolve, reject) => {
      this.http.post((environment.env == 'dev' ? environment.dev_api_url : environment.prod_api_url), body.toString(), headers).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp != null) {
          if (temp['result'] == "success") {
            resolve(temp);
          } else if (temp['result'] == "fail") {
            resolve(temp);
          } else if (temp['result'] == "error") {
            reject(temp);
          }
        }
        else {
          reject(null);
        }

      }),
        async error => {
          return error;
        };
    });
  }
}
