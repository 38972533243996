import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'blank',
    loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'car',
    loadChildren: () => import('./pages/car/car.module').then(m => m.CarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./pages/user-detail/user-detail.module').then(m => m.UserDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'car-detail',
    loadChildren: () => import('./pages/car-detail/car-detail.module').then(m => m.CarDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'coupon',
    loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'coupon-detail',
    loadChildren: () => import('./pages/coupon-detail/coupon-detail.module').then(m => m.CouponDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parking',
    loadChildren: () => import('./pages/parking/parking.module').then(m => m.ParkingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parking-detail',
    loadChildren: () => import('./pages/parking-detail/parking-detail.module').then(m => m.ParkingDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign',
    loadChildren: () => import('./pages/campaign/campaign.module').then(m => m.CampaignPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign-detail',
    loadChildren: () => import('./pages/campaign-detail/campaign-detail.module').then(m => m.CampaignDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment',
    loadChildren: () => import('./pages/equipment/equipment.module').then(m => m.EquipmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment-detail',
    loadChildren: () => import('./pages/equipment-detail/equipment-detail.module').then(m => m.EquipmentDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product',
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deposit',
    loadChildren: () => import('./pages/deposit/deposit.module').then(m => m.DepositPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deposit-detail',
    loadChildren: () => import('./pages/deposit-detail/deposit-detail.module').then(m => m.DepositDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'blocking',
    loadChildren: () => import('./pages/blocking/blocking.module').then(m => m.BlockingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'emergency',
    loadChildren: () => import('./pages/emergency/emergency.module').then(m => m.EmergencyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'emergency-detail',
    loadChildren: () => import('./pages/emergency-detail/emergency-detail.module').then(m => m.EmergencyDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'violation',
    loadChildren: () => import('./pages/violation/violation.module').then(m => m.ViolationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'violation-detail',
    loadChildren: () => import('./pages/violation-detail/violation-detail.module').then(m => m.ViolationDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenancePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'maintenance-detail',
    loadChildren: () => import('./pages/maintenance-detail/maintenance-detail.module').then(m => m.MaintenanceDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appointment',
    loadChildren: () => import('./pages/appointment/appointment.module').then(m => m.AppointmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appointment-detail',
    loadChildren: () => import('./pages/appointment-detail/appointment-detail.module').then(m => m.AppointmentDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'factory',
    loadChildren: () => import('./pages/factory/factory.module').then(m => m.FactoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'factory-detail',
    loadChildren: () => import('./pages/factory-detail/factory-detail.module').then(m => m.FactoryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-order',
    loadChildren: () => import('./pages/new-order/new-order.module').then(m => m.NewOrderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gift',
    loadChildren: () => import('./pages/gift/gift.module').then(m => m.GiftPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gift-detail',
    loadChildren: () => import('./pages/gift-detail/gift-detail.module').then(m => m.GiftDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'plate',
    loadChildren: () => import('./pages/plate/plate.module').then(m => m.PlatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'plate-detail',
    loadChildren: () => import('./pages/plate-detail/plate-detail.module').then(m => m.PlateDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rbb-equipment',
    loadChildren: () => import('./pages/rbb-equipment/rbb-equipment.module').then(m => m.RbbEquipmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rbb-equipment-detail',
    loadChildren: () => import('./pages/rbb-equipment-detail/rbb-equipment-detail.module').then(m => m.RbbEquipmentDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rbb',
    loadChildren: () => import('./pages/rbb/rbb.module').then(m => m.RbbPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rbb-detail',
    loadChildren: () => import('./pages/rbb-detail/rbb-detail.module').then(m => m.RbbDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'promotion',
    loadChildren: () => import('./pages/promotion/promotion.module').then(m => m.PromotionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'promotion-detail',
    loadChildren: () => import('./pages/promotion-detail/promotion-detail.module').then(m => m.PromotionDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification-detail',
    loadChildren: () => import('./pages/notification-detail/notification-detail.module').then(m => m.NotificationDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-detail',
    loadChildren: () => import('./pages/admin-detail/admin-detail.module').then(m => m.AdminDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq-detail',
    loadChildren: () => import('./pages/faq-detail/faq-detail.module').then(m => m.FaqDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-notification',
    loadChildren: () => import('./pages/admin-notification/admin-notification.module').then(m => m.AdminNotificationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vra',
    loadChildren: () => import('./pages/vra/vra.module').then(m => m.VraPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cva',
    loadChildren: () => import('./pages/cva/cva.module').then(m => m.CvaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rvr',
    loadChildren: () => import('./pages/rvr/rvr.module').then(m => m.RvrPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vls',
    loadChildren: () => import('./pages/vls/vls.module').then(m => m.VlsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vrf',
    loadChildren: () => import('./pages/vrf/vrf.module').then(m => m.VrfPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'daf',
    loadChildren: () => import('./pages/daf/daf.module').then(m => m.DafPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rdr',
    loadChildren: () => import('./pages/rdr/rdr.module').then( m => m.RdrPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'log',
    loadChildren: () => import('./pages/log/log.module').then( m => m.LogPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'favorite-car',
    loadChildren: () => import('./pages/favorite-car/favorite-car.module').then( m => m.FavoriteCarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment-report',
    loadChildren: () => import('./pages/equipment-report/equipment-report.module').then( m => m.EquipmentReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-report',
    loadChildren: () => import('./pages/product-report/product-report.module').then( m => m.ProductReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'car-overview',
    loadChildren: () => import('./pages/car-overview/car-overview.module').then( m => m.CarOverviewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'outstanding-payment',
    loadChildren: () => import('./pages/outstanding-payment/outstanding-payment.module').then( m => m.OutstandingPaymentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'banner-setting',
    loadChildren: () => import('./pages/banner-setting/banner-setting.module').then( m => m.BannerSettingPageModule)
  },
  {
    path: 'seven-coupon',
    loadChildren: () => import('./pages/seven-coupon/seven-coupon.module').then( m => m.SevenCouponPageModule)
  },
  {
    path: 'seven-coupon-detail',
    loadChildren: () => import('./pages/seven-coupon-detail/seven-coupon-detail.module').then( m => m.SevenCouponDetailPageModule)
  },
  {
    path: 'form-list',
    loadChildren: () => import('./pages/charity-form-list/charity-form-list.module').then( m => m.CharityFormListPageModule)
  },
  {
    path: 'form',
    loadChildren: () => import('./pages/charity-form/charity-form.module').then( m => m.CharityFormPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
